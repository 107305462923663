import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from "./notification";
import { MESSAGE_TYPE } from "store/constant";
import axiosService from "services/axios.service";
import { allRolesArray } from "utils/adminRolesOptions";
import { handleThrowError, handleThrowSuccess } from "utils/alerts";

// initial values
const authData = {
  loading: false,
  currentUser: {},
  loggedinUserDetails: {},
};

// Action types
const REGISTERED = "app/auth/REGISTERED";
const LOGGED_IN = "app/auth/LOGGED_IN";
const LOGGED_OUT = "app/auth/LOGGED_OUT";
const LOADING = "app/auth/LOADING";
const LOGGED_IN_USER = "app/auth/LOGGED_IN_USER";

// Reducer
export default function reducer(state = authData, action = {}) {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        error: null,
        fetching: false,
        currentUser: action.payload,
      };
    case LOGGED_OUT:
      return {
        ...state,
        error: null,
        fetching: false,
        currentUser: null,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOGGED_IN_USER:
      return {
        ...state,
        error: null,
        fetching: false,
        loggedInUserDetails: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function userRegistered() {
  return { type: REGISTERED };
}

export function userLoggedIn(user) {
  return { type: LOGGED_IN, payload: user };
}

export function isRequestLoading(payload) {
  return {
    type: LOADING,
    payload,
  };
}

export function userLoggedOut() {
  return { type: LOGGED_OUT };
}

export function userDetails(user) {
  return { type: LOGGED_IN_USER, payload: user };
}

export function loginUser({ username, password, role, }, navigate, redirectURL) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.Auth.login(username, password, role).then(
      (response) => {
        // handle success
        const { access_type } = response.data.data;
        // IRRESPECTIVE OF ROLE, IF USER IS HAS A REDIRECT URL, REDIRECT USER TO THE URL  
        if (redirectURL) {
          onLogin(dispatch, response, navigate);
          dispatch(isRequestLoading(false));
          navigate(redirectURL)
          return;
        }

        // FOR ADMINS
        if (role === "admin") {
          if (access_type === "DATAMAX ADMIN") {
            onLogin(dispatch, response, navigate);
            dispatch(isRequestLoading(false));
            // navigate("/admin/license-management");
            window.location.href = "/admin/license-management"
            return;
          }

          if (access_type !== "General staff" && allRolesArray.includes(access_type)) {
            onLogin(dispatch, response, navigate);
            dispatch(isRequestLoading(false));
            if (access_type === "File Control Admin" || access_type === "Auditor" || access_type === "COMPANY ADMIN") {
              // navigate("/admin/category");
              window.location.href = "/admin/category"
            } else {
              // navigate("/admin/dashboard");
              window.location.href = "/admin/dashboard"
            }
            return;
          }

        }

        if (role === "user" && access_type !== "DATAMAX ADMIN") {
          onLogin(dispatch, response, navigate);
          navigate("/dashboard");
          dispatch(isRequestLoading(false));
          return;

        } else {
          dispatch(
            showWarningMessage({
              type: MESSAGE_TYPE.ERROR,
              message: "Invalid role, Try again with the correct role",
              title: "Invalid role",
            })
          );
        }
        dispatch(isRequestLoading(false));
        return;
      },
      (error) => {
        // handle error
        dispatch(
          showErrorMessage({
            type: "error",
            message: error?.response?.data?.message,
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function changePassword({ current_password, new_password }) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.User.updatePassword({
      current_password,
      new_password,
    }).then(
      (response) => {
        dispatch(isRequestLoading(false));
        dispatch(
          showSuccessMessage({
            type: MESSAGE_TYPE.SUCCESS,
            message: "Password successfully changed",
            title: "Success",
          })
        );
        document.getElementById("closeChangePasswordModal").click();
        dispatch(OnLogout());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error.response.data.message,
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

export function uploadSignature(signature, setIsModalOpened) {
  return (dispatch) => {
    dispatch(isRequestLoading(true));
    return axiosService.User.uploadSignature(signature).then(
      (response) => {
        dispatch(isRequestLoading(false));
        // Close all opened modals
        document.getElementById("closeUploadSignatureModal").click();
        setIsModalOpened && setIsModalOpened(false)
        // Throw success and refetch user details to update signature
        handleThrowSuccess(dispatch, "Signature successfully uploaded")
        dispatch(loggedInUser());
      },
      (error) => {
        dispatch(
          showErrorMessage({
            type: "error",
            message: error.response.data.message,
          })
        );
        dispatch(isRequestLoading(false));
      }
    );
  };
}

function onLogin(dispatch, user, navigate) {
  axiosService.Auth.saveAuthData(user);
  loggedInUser(navigate);
  dispatch(userLoggedIn(user));
}

export function loggedInUser(navigate) {
  return (dispatch) => {
    axiosService.User.signedUserDetail()
      .then((response) => {
        dispatch(userDetails(response));



        // Handling Errors
        const errorObject = response?.response
        // Check if response status is not between 200 and 299
        if (errorObject?.status < 200 || errorObject?.status > 299) {
          if (errorObject?.data?.message === "You are not authenticated, Please login") {
            // If error is non-authentication, check if user is in the preview page, if yes, navigate to login noting the preview link and after authentication, navigate user to the preview link, if not just navigate user to the login page
            if (window.location.pathname.includes("/preview-file")) {
              navigate(`/login?preview=${window.location.pathname}`)
            } else {
              navigate("/login", { state: { toastMessage: errorObject?.data?.message } })
            }
          } else {
            // Throw error
            handleThrowError(dispatch, errorObject?.data?.message || "An error occured")
          }
          return;
        }
      }
      )
  };
}

export function OnLogout(navigate, message) {
  return (dispatch) => {
    axiosService.Auth.logoutUser().then(() => {
      axiosService.Auth.logout()
      dispatch(userLoggedOut());
      // Take user to sign in page
      if (navigate && message) {
        navigate("/", { state: { toastMessage: message } });
      } else {
        window.location.href = "/"
      }

    }, () => {
      axiosService.Auth.logout()
      dispatch(userLoggedOut());
      // Take user to sign in page
      if (navigate && message) {
        navigate("/", { state: { toastMessage: message } });
      } else {
        window.location.href = "/"
      }
    })
  };
}
